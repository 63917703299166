export const vars = {
  API_ROUTE: 'pfs3/wapi/',
  SUCCESS_API_CODE: '000',
  DEFAULT_LANGUAGE: 'en',
  METHODS_ICON: {
    0: 'sp0.svg',
    1: 'sp1.svg',
    2: 'sp2.svg',
    3: 'sp3.svg',
    4: 'sp4.svg',
    5: 'sp5.svg',
    6: 'sp6.svg',
    7: 'sp7.svg',
    8: 'sp8.svg',
    9: 'sp9.svg',
    10: 'sp10.svg',
    11: 'sp11.svg',
    12: 'sp12.svg',
    13: 'sp12.svg',
    14: 'sp14.svg',
    15: 'sp15.svg',
    16: 'sp16.svg',
    18: 'sp18.svg',
    22: 'sp10.svg',
    24: 'sp24.svg',
    25: 'sp25.svg',
    26: 'sp26.svg',
    27: 'sp10.svg',
    28: 'sp3.svg',
  },
  KIND_IDS: {
    CRYPTO: 4,
    BY_REQUISITES: 7,
  },
  AUTH_TYPE: {
    SIMPLE: 1,
    TWO_FACTOR: 2,
  },
  STATUS: {
    NEW: 1,
    SUCCESS: 2,
    FAILURE: 3,
    PENDING: 4,
    SECOND_SUCCESS: 5,
    ICONS: {
      2: 'outline-check-circle-outline-24-px.svg',
      3: 'highlight-off-24-px.svg',
      4: 'hourglass-full-24-px.svg',
      5: 'outline-check-circle-outline-24-px.svg',
    },
  },
  PAYMENT_SYSTEM_TYPE_ID: {
    SHARPAY: 0,
    CRYPTO: 15,
  },
  VIEW: {
    DESKTOP: 'desktop',
    MOBILE: 'mobile',
  }
};

export const selectStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%',
  }),
  control: (provided, state) => ({
    ...provided,
    height: '40px',
    minHeight: '40px',
    border: 'none',
    borderRadius: '20px',
    boxShadow: '0 4px 27px 0 rgba(0, 0, 128, 0.08)',
    backgroundColor: state.isDisabled ? '#e6e6e6' : provided.backgroundColor,
    padding: '0 10px',
    fontSize: '12px',
    '&:hover': {
      border: 'none',
    },
    width: '100%',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? '#e6e6e6' : state.isFocused ? '#e6e6e6' : 'white',
    color: 'black',
    height: '40px',
    fontSize: '12px',
    display: 'flex',
    alignItems: 'center',
  }),
  singleValue: (provided) => ({
    ...provided,
    fontSize: '12px',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: '0 4px 27px 0 rgba(0, 0, 128, 0.08)',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '250px',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: '4px',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
};

export const countryList = [
  {
    "value": "AFG",
    "label": "Afghanistan",
  },
  {
    "value": "ALA",
    "label": "Åland Islands",
  },
  {
    "value": "ALB",
    "label": "Albania",
  },
  {
    "value": "DZA",
    "label": "Algeria",
  },
  {
    "value": "ASM",
    "label": "American Samoa",
  },
  {
    "value": "AND",
    "label": "Andorra",
  },
  {
    "value": "AGO",
    "label": "Angola",
  },
  {
    "value": "AIA",
    "label": "Anguilla",
  },
  {
    "value": "ATA",
    "label": "Antarctica",
  },
  {
    "value": "ATG",
    "label": "Antigua and Barbuda",
  },
  {
    "value": "ARG",
    "label": "Argentina",
  },
  {
    "value": "ARM",
    "label": "Armenia",
  },
  {
    "value": "ABW",
    "label": "Aruba",
  },
  {
    "value": "AUS",
    "label": "Australia",
  },
  {
    "value": "AUT",
    "label": "Austria",
  },
  {
    "value": "AZE",
    "label": "Azerbaijan",
  },
  {
    "value": "BHS",
    "label": "Bahamas",
  },
  {
    "value": "BHR",
    "label": "Bahrain",
  },
  {
    "value": "BGD",
    "label": "Bangladesh",
  },
  {
    "value": "BRB",
    "label": "Barbados",
  },
  {
    "value": "BLR",
    "label": "Belarus",
  },
  {
    "value": "BEL",
    "label": "Belgium",
  },
  {
    "value": "BLZ",
    "label": "Belize",
  },
  {
    "value": "BEN",
    "label": "Benin",
  },
  {
    "value": "BMU",
    "label": "Bermuda",
  },
  {
    "value": "BTN",
    "label": "Bhutan",
  },
  {
    "value": "BOL",
    "label": "Bolivia, Plurinational State of",
  },
  {
    "value": "BES",
    "label": "Bonaire, Sint Eustatius and Saba",
  },
  {
    "value": "BIH",
    "label": "Bosnia and Herzegovina",
  },
  {
    "value": "BWA",
    "label": "Botswana",
  },
  {
    "value": "BVT",
    "label": "Bouvet Island",
  },
  {
    "value": "BRA",
    "label": "Brazil",
  },
  {
    "value": "IOT",
    "label": "British Indian Ocean Territory",
  },
  {
    "value": "BRN",
    "label": "Brunei Darussalam",
  },
  {
    "value": "BGR",
    "label": "Bulgaria",
  },
  {
    "value": "BFA",
    "label": "Burkina Faso",
  },
  {
    "value": "BDI",
    "label": "Burundi",
  },
  {
    "value": "KHM",
    "label": "Cambodia",
  },
  {
    "value": "CMR",
    "label": "Cameroon",
  },
  {
    "value": "CAN",
    "label": "Canada",
  },
  {
    "value": "CPV",
    "label": "Cape Verde",
  },
  {
    "value": "CYM",
    "label": "Cayman Islands",
  },
  {
    "value": "CAF",
    "label": "Central African Republic",
  },
  {
    "value": "TCD",
    "label": "Chad",
  },
  {
    "value": "CHL",
    "label": "Chile",
  },
  {
    "value": "CHN",
    "label": "China",
  },
  {
    "value": "CXR",
    "label": "Christmas Island",
  },
  {
    "value": "CCK",
    "label": "Cocos (Keeling) Islands",
  },
  {
    "value": "COL",
    "label": "Colombia",
  },
  {
    "value": "COM",
    "label": "Comoros",
  },
  {
    "value": "COG",
    "label": "Congo",
  },
  {
    "value": "COD",
    "label": "Congo, the Democratic Republic of the",
  },
  {
    "value": "COK",
    "label": "Cook Islands",
  },
  {
    "value": "CRI",
    "label": "Costa Rica",
  },
  {
    "value": "CIV",
    "label": "Côte d'Ivoire",
  },
  {
    "value": "HRV",
    "label": "Croatia",
  },
  {
    "value": "CUB",
    "label": "Cuba",
  },
  {
    "value": "CUW",
    "label": "Curaçao",
  },
  {
    "value": "CYP",
    "label": "Cyprus",
  },
  {
    "value": "CZE",
    "label": "Czech Republic",
  },
  {
    "value": "DNK",
    "label": "Denmark",
  },
  {
    "value": "DJI",
    "label": "Djibouti",
  },
  {
    "value": "DMA",
    "label": "Dominica",
  },
  {
    "value": "DOM",
    "label": "Dominican Republic",
  },
  {
    "value": "ECU",
    "label": "Ecuador",
  },
  {
    "value": "EGY",
    "label": "Egypt",
  },
  {
    "value": "SLV",
    "label": "El Salvador",
  },
  {
    "value": "GNQ",
    "label": "Equatorial Guinea",
  },
  {
    "value": "ERI",
    "label": "Eritrea",
  },
  {
    "value": "EST",
    "label": "Estonia",
  },
  {
    "value": "ETH",
    "label": "Ethiopia",
  },
  {
    "value": "FLK",
    "label": "Falkland Islands (Malvinas)",
  },
  {
    "value": "FRO",
    "label": "Faroe Islands",
  },
  {
    "value": "FJI",
    "label": "Fiji",
  },
  {
    "value": "FIN",
    "label": "Finland",
  },
  {
    "value": "FRA",
    "label": "France",
  },
  {
    "value": "GUF",
    "label": "French Guiana",
  },
  {
    "value": "PYF",
    "label": "French Polynesia",
  },
  {
    "value": "ATF",
    "label": "French Southern Territories",
  },
  {
    "value": "GAB",
    "label": "Gabon",
  },
  {
    "value": "GMB",
    "label": "Gambia",
  },
  {
    "value": "GEO",
    "label": "Georgia",
  },
  {
    "value": "DEU",
    "label": "Germany",
  },
  {
    "value": "GHA",
    "label": "Ghana",
  },
  {
    "value": "GIB",
    "label": "Gibraltar",
  },
  {
    "value": "GRC",
    "label": "Greece",
  },
  {
    "value": "GRL",
    "label": "Greenland",
  },
  {
    "value": "GRD",
    "label": "Grenada",
  },
  {
    "value": "GLP",
    "label": "Guadeloupe",
  },
  {
    "value": "GUM",
    "label": "Guam",
  },
  {
    "value": "GTM",
    "label": "Guatemala",
  },
  {
    "value": "GGY",
    "label": "Guernsey",
  },
  {
    "value": "GIN",
    "label": "Guinea",
  },
  {
    "value": "GNB",
    "label": "Guinea-Bissau",
  },
  {
    "value": "GUY",
    "label": "Guyana",
  },
  {
    "value": "HTI",
    "label": "Haiti",
  },
  {
    "value": "HMD",
    "label": "Heard Island and McDonald Islands",
  },
  {
    "value": "VAT",
    "label": "Holy See (Vatican City State)",
  },
  {
    "value": "HND",
    "label": "Honduras",
  },
  {
    "value": "HKG",
    "label": "Hong Kong",
  },
  {
    "value": "HUN",
    "label": "Hungary",
  },
  {
    "value": "ISL",
    "label": "Iceland",
  },
  {
    "value": "IND",
    "label": "India",
  },
  {
    "value": "IDN",
    "label": "Indonesia",
  },
  {
    "value": "IRN",
    "label": "Iran, Islamic Republic of",
  },
  {
    "value": "IRQ",
    "label": "Iraq",
  },
  {
    "value": "IRL",
    "label": "Ireland",
  },
  {
    "value": "IMN",
    "label": "Isle of Man",
  },
  {
    "value": "ISR",
    "label": "Israel",
  },
  {
    "value": "ITA",
    "label": "Italy",
  },
  {
    "value": "JAM",
    "label": "Jamaica",
  },
  {
    "value": "JPN",
    "label": "Japan",
  },
  {
    "value": "JEY",
    "label": "Jersey",
  },
  {
    "value": "JOR",
    "label": "Jordan",
  },
  {
    "value": "KAZ",
    "label": "Kazakhstan",
  },
  {
    "value": "KEN",
    "label": "Kenya",
  },
  {
    "value": "KIR",
    "label": "Kiribati",
  },
  {
    "value": "PRK",
    "label": "Korea, Democratic People's Republic of",
  },
  {
    "value": "KOR",
    "label": "Korea, Republic of",
  },
  {
    "value": "KWT",
    "label": "Kuwait",
  },
  {
    "value": "KGZ",
    "label": "Kyrgyzstan",
  },
  {
    "value": "LAO",
    "label": "Lao People's Democratic Republic",
  },
  {
    "value": "LVA",
    "label": "Latvia",
  },
  {
    "value": "LBN",
    "label": "Lebanon",
  },
  {
    "value": "LSO",
    "label": "Lesotho",
  },
  {
    "value": "LBR",
    "label": "Liberia",
  },
  {
    "value": "LBY",
    "label": "Libya",
  },
  {
    "value": "LIE",
    "label": "Liechtenstein",
  },
  {
    "value": "LTU",
    "label": "Lithuania",
  },
  {
    "value": "LUX",
    "label": "Luxembourg",
  },
  {
    "value": "MAC",
    "label": "Macao",
  },
  {
    "value": "MKD",
    "label": "Macedonia, the former Yugoslav Republic of",
  },
  {
    "value": "MDG",
    "label": "Madagascar",
  },
  {
    "value": "MWI",
    "label": "Malawi",
  },
  {
    "value": "MYS",
    "label": "Malaysia",
  },
  {
    "value": "MDV",
    "label": "Maldives",
  },
  {
    "value": "MLI",
    "label": "Mali",
  },
  {
    "value": "MLT",
    "label": "Malta",
  },
  {
    "value": "MHL",
    "label": "Marshall Islands",
  },
  {
    "value": "MTQ",
    "label": "Martinique",
  },
  {
    "value": "MRT",
    "label": "Mauritania",
  },
  {
    "value": "MUS",
    "label": "Mauritius",
  },
  {
    "value": "MYT",
    "label": "Mayotte",
  },
  {
    "value": "MEX",
    "label": "Mexico",
  },
  {
    "value": "FSM",
    "label": "Micronesia, Federated States of",
  },
  {
    "value": "MDA",
    "label": "Moldova, Republic of",
  },
  {
    "value": "MCO",
    "label": "Monaco",
  },
  {
    "value": "MNG",
    "label": "Mongolia",
  },
  {
    "value": "MNE",
    "label": "Montenegro",
  },
  {
    "value": "MSR",
    "label": "Montserrat",
  },
  {
    "value": "MAR",
    "label": "Morocco",
  },
  {
    "value": "MOZ",
    "label": "Mozambique",
  },
  {
    "value": "MMR",
    "label": "Myanmar",
  },
  {
    "value": "NAM",
    "label": "Namibia",
  },
  {
    "value": "NRU",
    "label": "Nauru",
  },
  {
    "value": "NPL",
    "label": "Nepal",
  },
  {
    "value": "NLD",
    "label": "Netherlands",
  },
  {
    "value": "NCL",
    "label": "New Caledonia",
  },
  {
    "value": "NZL",
    "label": "New Zealand",
  },
  {
    "value": "NIC",
    "label": "Nicaragua",
  },
  {
    "value": "NER",
    "label": "Niger",
  },
  {
    "value": "NGA",
    "label": "Nigeria",
  },
  {
    "value": "NIU",
    "label": "Niue",
  },
  {
    "value": "NFK",
    "label": "Norfolk Island",
  },
  {
    "value": "MNP",
    "label": "Northern Mariana Islands",
  },
  {
    "value": "NOR",
    "label": "Norway",
  },
  {
    "value": "OMN",
    "label": "Oman",
  },
  {
    "value": "PAK",
    "label": "Pakistan",
  },
  {
    "value": "PLW",
    "label": "Palau",
  },
  {
    "value": "PSE",
    "label": "Palestinian Territory, Occupied",
  },
  {
    "value": "PAN",
    "label": "Panama",
  },
  {
    "value": "PNG",
    "label": "Papua New Guinea",
  },
  {
    "value": "PRY",
    "label": "Paraguay",
  },
  {
    "value": "PER",
    "label": "Peru",
  },
  {
    "value": "PHL",
    "label": "Philippines",
  },
  {
    "value": "PCN",
    "label": "Pitcairn",
  },
  {
    "value": "POL",
    "label": "Poland",
  },
  {
    "value": "PRT",
    "label": "Portugal",
  },
  {
    "value": "PRI",
    "label": "Puerto Rico",
  },
  {
    "value": "QAT",
    "label": "Qatar",
  },
  {
    "value": "REU",
    "label": "Réunion",
  },
  {
    "value": "ROU",
    "label": "Romania",
  },
  {
    "value": "RUS",
    "label": "Russian Federation",
  },
  {
    "value": "RWA",
    "label": "Rwanda",
  },
  {
    "value": "BLM",
    "label": "Saint Barthélemy",
  },
  {
    "value": "SHN",
    "label": "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    "value": "KNA",
    "label": "Saint Kitts and Nevis",
  },
  {
    "value": "LCA",
    "label": "Saint Lucia",
  },
  {
    "value": "MAF",
    "label": "Saint Martin (French part)",
  },
  {
    "value": "SPM",
    "label": "Saint Pierre and Miquelon",
  },
  {
    "value": "VCT",
    "label": "Saint Vincent and the Grenadines",
  },
  {
    "value": "WSM",
    "label": "Samoa",
  },
  {
    "value": "SMR",
    "label": "San Marino",
  },
  {
    "value": "STP",
    "label": "Sao Tome and Principe",
  },
  {
    "value": "SAU",
    "label": "Saudi Arabia",
  },
  {
    "value": "SEN",
    "label": "Senegal",
  },
  {
    "value": "SRB",
    "label": "Serbia",
  },
  {
    "value": "SYC",
    "label": "Seychelles",
  },
  {
    "value": "SLE",
    "label": "Sierra Leone",
  },
  {
    "value": "SGP",
    "label": "Singapore",
  },
  {
    "value": "SXM",
    "label": "Sint Maarten (Dutch part)",
  },
  {
    "value": "SVK",
    "label": "Slovakia",
  },
  {
    "value": "SVN",
    "label": "Slovenia",
  },
  {
    "value": "SLB",
    "label": "Solomon Islands",
  },
  {
    "value": "SOM",
    "label": "Somalia",
  },
  {
    "value": "ZAF",
    "label": "South Africa",
  },
  {
    "value": "SGS",
    "label": "South Georgia and the South Sandwich Islands",
  },
  {
    "value": "SSD",
    "label": "South Sudan",
  },
  {
    "value": "ESP",
    "label": "Spain",
  },
  {
    "value": "LKA",
    "label": "Sri Lanka",
  },
  {
    "value": "SDN",
    "label": "Sudan",
  },
  {
    "value": "SUR",
    "label": "Surilabel",
  },
  {
    "value": "SJM",
    "label": "Svalbard and Jan Mayen",
  },
  {
    "value": "SWZ",
    "label": "Swaziland",
  },
  {
    "value": "SWE",
    "label": "Sweden",
  },
  {
    "value": "CHE",
    "label": "Switzerland",
  },
  {
    "value": "SYR",
    "label": "Syrian Arab Republic",
  },
  {
    "value": "TWN",
    "label": "Taiwan, Province of China",
  },
  {
    "value": "TJK",
    "label": "Tajikistan",
  },
  {
    "value": "TZA",
    "label": "Tanzania, United Republic of",
  },
  {
    "value": "THA",
    "label": "Thailand",
  },
  {
    "value": "TLS",
    "label": "Timor-Leste",
  },
  {
    "value": "TGO",
    "label": "Togo",
  },
  {
    "value": "TKL",
    "label": "Tokelau",
  },
  {
    "value": "TON",
    "label": "Tonga",
  },
  {
    "value": "TTO",
    "label": "Trinidad and Tobago",
  },
  {
    "value": "TUN",
    "label": "Tunisia",
  },
  {
    "value": "TUR",
    "label": "Turkey",
  },
  {
    "value": "TKM",
    "label": "Turkmenistan",
  },
  {
    "value": "TCA",
    "label": "Turks and Caicos Islands",
  },
  {
    "value": "TUV",
    "label": "Tuvalu",
  },
  {
    "value": "UGA",
    "label": "Uganda",
  },
  {
    "value": "UKR",
    "label": "Ukraine",
  },
  {
    "value": "ARE",
    "label": "United Arab Emirates",
  },
  {
    "value": "GBR",
    "label": "United Kingdom",
  },
  {
    "value": "USA",
    "label": "United States",
  },
  {
    "value": "UMI",
    "label": "United States Minor Outlying Islands",
  },
  {
    "value": "URY",
    "label": "Uruguay",
  },
  {
    "value": "UZB",
    "label": "Uzbekistan",
  },
  {
    "value": "VUT",
    "label": "Vanuatu",
  },
  {
    "value": "VEN",
    "label": "Venezuela, Bolivarian Republic of",
  },
  {
    "value": "VNM",
    "label": "Viet Nam",
  },
  {
    "value": "VGB",
    "label": "Virgin Islands, British",
  },
  {
    "value": "VIR",
    "label": "Virgin Islands, U.S.",
  },
  {
    "value": "WLF",
    "label": "Wallis and Futuna",
  },
  {
    "value": "ESH",
    "label": "Western Sahara",
  },
  {
    "value": "YEM",
    "label": "Yemen",
  },
  {
    "value": "ZMB",
    "label": "Zambia",
  },
  {
    "value": "ZWE",
    "label": "Zimbabwe",
  }
];